<template>
  <div class="d-flex flex-row">
    <!--begin::Aside-->
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <!--begin::Card-->
      <div class="card card-custom">
        <!--begin::Body-->
        <div class="card-body pt-15">
          <!--begin::User-->
          <div class="text-center mb-10">
            <div class="symbol symbol-60 symbol-circle symbol-xl-90">
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${photo})`
                }"
              ></div>
              <i class="symbol-badge symbol-badge-bottom bg-success"></i>
            </div>

            <h4 class="font-weight-bold my-2">
              {{ currentLogin.name }}
            </h4>
            <div class="text-muted mb-2">
              {{ currentLogin.role.name }}
            </div>
            <span
              class="label label-light-warning label-inline font-weight-bold label-lg"
              >Active</span
            >
          </div>
          <!--end::User-->

          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2">
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block active"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
              >
                Account Info
              </a>
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
              >
                Change Password
              </a>
              <a
                class="btn btn-hover-light-primary font-weight-bold py-3 px-6 mb-2 text-center btn-block "
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="2"
                data-toggle="tab"
                role="tab"
              >
                Personal info
              </a>
            </div>
          </div>
          <!--end::Nav-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Aside-->

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <KTAccountInformation :dataUser="currentLogin"></KTAccountInformation>
        </b-tab>

        <b-tab>
          <KTChangePassword></KTChangePassword>
        </b-tab>

        <b-tab>
          <KTPersonalInformation
            :dataUser="currentLogin"
          ></KTPersonalInformation>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTPersonalInformation from "./profile-comp/PersonalInformation";
import KTAccountInformation from "./profile-comp/AccountInformation";
import KTChangePassword from "./profile-comp/ChangePassword";
import { GET_USER_DETAIL_BY_ID } from "@/core/services/store/user.module";

export default {
  name: "Profile",
  data() {
    return {
      tabIndex: 0,
      default_photo: "media/users/blank.png",
      current_photo: null
    };
  },
  components: {
    KTPersonalInformation,
    KTAccountInformation,
    KTChangePassword
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);
    this.onGetUserDetail(this.currentUser.data.id);
    this.current_photo = this.currentLogin.pic.avatar;
  },
  methods: {
    onGetUserDetail(id) {
      this.$store.dispatch(GET_USER_DETAIL_BY_ID, id);
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('[data-toggle="tab"]');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPhoto", "currentUserDetail", "currentUser","currentUserPersonal"]),
    currentLogin() {
      return this.currentUserDetail;
    },

    currentPersonal(){
      return this.currentUserPersonal;
    },

      photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
