<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Account Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Change your account settings</span
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Account:</h5>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Username</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              ref="username"
              :value="dataUser.username"
              disabled
            />
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
          <div class="col-lg-9 col-xl-6">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-lg form-control-solid"
                ref="email"
                :value="dataUser.email"
                placeholder="Email"
              />
            </div>
          </div>
        </div>
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">Status</label>
          <div class="col-lg-9 col-xl-6">
            <select
              class="form-control form-control-lg form-control-solid"
              ref="Status"
              disabled
            >
              <template v-for="(item, i) in status">
                <option
                  v-bind:key="i"
                  :value="item"
                  :selected="dataUser.pic.status == item ? true : false"
                  >{{ item }}</option
                >
              </template>
            </select>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_ACCOUNT_INFO } from "@/core/services/store/profile.module";

export default {
  name: "AccountInformation",
  props: {
    dataUser: {
      required: true
    }
  },
  data() {
    return {
      status: ["active", "inactive"]
    };
  },
  methods: {
    save() {
      var username = this.$refs.username.value;
      var email = this.$refs.email.value;
      var language = this.$refs.language.value;
      var time_zone = this.$refs.time_zone.value;
      var communication = {
        email: this.$refs.email_com.checked,
        sms: this.$refs.sms_com.checked,
        phone: this.$refs.phone_com.checked
      };
      var verification = this.$refs.verification.checked;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_ACCOUNT_INFO, {
          username,
          email,
          language,
          time_zone,
          communication,
          verification
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.username.value = this.currentUserAccountInfo.username;
      this.$refs.email.value = this.currentUserAccountInfo.email;
      this.$refs.language.value = this.currentUserAccountInfo.language;
      this.$refs.time_zone.value = this.currentUserAccountInfo.time_zone;
      this.$refs.email_com.checked = this.currentUserAccountInfo.communication.email;
      this.$refs.sms_com.checked = this.currentUserAccountInfo.communication.sms;
      this.$refs.phone_com.checked = this.currentUserAccountInfo.communication.phone;
      this.$refs.verification.checked = this.currentUserAccountInfo.verification;
    }
  },
  computed: {
    ...mapGetters(["currentUserAccountInfo"])
  }
};
</script>
